// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------


$custom-primary: (
  50: var(--color-primary),
  100: var(--color-primary),
  200: var(--color-primary),
  300: var(--color-primary),
  400: var(--color-primary),
  500: var(--color-primary),
  600: var(--color-primary),
  700: var(--color-primary),
  800: var(--color-primary),
  900: var(--color-primary),
  A100: var(--color-primary),
  A200: var(--color-primary),
  A400: var(--color-primary),
  A700: var(--color-primary),
  contrast: (50: var(--color-primary-contrast),
    100: var(--color-primary-contrast),
    200: var(--color-primary-contrast),
    300: var(--color-primary-contrast),
    400: var(--color-primary-contrast),
    500: var(--color-primary-contrast),
    600: var(--color-primary-contrast),
    700: var(--color-primary-contrast),
    800: var(--color-primary-contrast),
    900: var(--color-primary-contrast),
    A100: var(--color-primary-contrast),
    A200: var(--color-primary-contrast),
    A400: var(--color-primary-contrast),
    A700: var(--color-primary-contrast),
  )
);

$custom-secondary: (
  50: #00000000,
  100: var(--color-secondary),
  200: var(--color-secondary),
  300: var(--color-secondary),
  400: var(--color-secondary),
  500: var(--color-secondary),
  600: var(--color-secondary),
  700: var(--color-secondary),
  800: var(--color-secondary),
  900: var(--color-secondary),
  A100: var(--color-secondary),
  A200: var(--color-secondary),
  A400: var(--color-secondary),
  A700: var(--color-secondary),
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: var(--color-secondary-contrast),
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$custom-accent: (
  50: var(--color-accent),
  100: var(--color-accent),
  200: var(--color-accent),
  300: var(--color-accent),
  400: var(--color-accent),
  500: var(--color-accent),
  600: var(--color-accent),
  700: var(--color-accent),
  800: var(--color-accent),
  900: var(--color-accent),
  A100: var(--color-accent),
  A200: var(--color-accent),
  A400: var(--color-accent),
  A700: var(--color-accent),
  contrast: (50: var(--color-accent-contrast),
    100: var(--color-accent-contrast),
    200: var(--color-accent-contrast),
    300: var(--color-accent-contrast),
    400: var(--color-accent-contrast),
    500: var(--color-accent-contrast),
    600: var(--color-accent-contrast),
    700: var(--color-accent-contrast),
    800: var(--color-accent-contrast),
    900: var(--color-accent-contrast),
    A100: var(--color-accent-contrast),
    A200: var(--color-accent-contrast),
    A400: var(--color-accent-contrast),
    A700: var(--color-accent-contrast))
);


// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-typography-config($font-family: var(--body-font),
    $body-2: mat.define-typography-level(14px, 24px, 600),
    $body-1: mat.define-typography-level(14px, 24px, 600),
    $button: mat.define-typography-level(14px, 14px, 600),
    $input: mat.define-typography-level(16px, 1.125, 400) // line-height must be unitless !!!
  );

$typography2: mat.define-typography-config($font-family: var(--title-font),
    $title: mat.define-typography-level(20px, 32px, 600), // line-height must be unitless !!!
    $headline: mat.define-typography-level(20px, 32px, 600) // line-height must be unitless !!!
  );

// Setup the typography
@include mat.all-component-typographies($typography);
@include mat.core($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "src/app/layout/components/toolbar/toolbar.theme";

@import "src/app/main/chat/chat.theme";
@import "src/app/main/pages/coming-soon/coming-soon.theme";
@import "src/app/main/operators/operators-list/operators-list.theme";

// Authentication
@import "src/app/main/authentication/login/login.theme";
@import "src/app/main/authentication/login-via-otp/login-via-otp.theme";
@import "src/app/main/authentication/register/register.theme";
@import "src/app/main/authentication/reset-password/reset-password.theme";
@import "src/app/main/authentication/forgot-password/forgot-password.theme";
@import "src/app/main/authentication/lock/lock.theme";
@import "src/app/main/authentication/mail-confirm/mail-confirm.theme";

// Modules
@import "src/app/main/adminbot/adminbot.theme";
@import "src/app/main/bank-transfer-transactions/bank-transfer-transactions.theme";
@import "src/app/main/business-statements/business-statements.theme";
@import "src/app/main/business-tax-returns/business-tax-returns.theme";
@import "src/app/main/clients/clients.theme";
@import "src/app/main/expenditures/expenditures.theme";
@import "src/app/main/expenses/expenses.theme";
@import "src/app/main/faq/faq.theme";
@import "src/app/main/import-data/import-data.theme";
@import "src/app/main/incomes/incomes.theme";
@import "src/app/main/insurances/insurances.theme";
@import "src/app/main/invoices/invoices.theme";
@import "src/app/main/jobs/jobs.theme";
@import "src/app/main/map/map.theme";
@import "src/app/main/marketing/marketing.theme";
@import "src/app/main/payouts/payouts.theme";
@import "src/app/main/privacy/privacy.theme";
@import "src/app/main/settlements/settlements.theme";
@import "src/app/main/sub-users/sub-users.theme";
@import "src/app/main/tax-cards/tax-cards.theme";
@import "src/app/main/taxi-rides/taxi-rides.theme";
@import "src/app/main/taxi-contracts/taxi-contracts.theme";
@import "src/app/main/terms/terms.theme";
@import "src/app/main/users/users.theme";
@import "src/app/main/vehicles/vehicles.theme";
@import "src/app/main/wallets/wallets.theme";
@import "src/app/main/work-hours/work-hours.theme";
@import "src/app/main/work-notices/work-notices.theme";
@import "src/app/main/work-shifts/work-shifts.theme";
@import "src/app/main/subscriptions/subscriptions.theme";
@import "src/app/main/user-subscriptions/user-subscriptions.theme";
@import "src/app/main/user-fees/user-fees.theme";
@import "src/app/main/liability-data/liability-data.theme";
@import "src/app/main/liability-search/liability-data-search/liability-data-search.theme";
@import "src/app/main/liability-search/my-liability-watchlist/my-liability-watchlist.theme";
@import "src/app/main/operator-fees/operator-fees.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {

  // Layout components
  @include toolbar-theme($theme);

  // Modules
  @include login-theme($theme);
  @include login-via-otp-theme($theme);
  @include clients-theme($theme);
  @include invoices-theme($theme);
  @include incomes-theme($theme);
  @include jobs-theme($theme);
  @include work-notices-theme($theme);
  @include expenses-theme($theme);
  @include payouts-theme($theme);
  @include tax-cards-theme($theme);
  @include wallets-theme($theme);
  @include users-theme($theme);
  @include insurances-theme($theme);
  @include faq-theme($theme);
  @include terms-theme($theme);
  @include privacy-theme($theme);
  @include marketing-theme($theme);
  @include settlements-theme($theme);
  @include map-theme($theme);
  @include work-hours-theme($theme);
  @include sub-users-theme($theme);
  @include vehicles-theme($theme);
  @include bank-transfer-transactions-theme($theme);
  @include import-data-theme($theme);
  @include business-statements-theme($theme);
  @include business-tax-returns-theme($theme);
  @include subscriptions-theme($theme);
  @include user-subscriptions-theme($theme);
  @include user-fees-theme($theme);
  @include liability-data-theme($theme);
  @include liability-data-search-theme($theme);
  @include my-liability-watchlist-theme($theme);
  @include operator-fees-theme($theme);

  // Apps
  @include app-operators-list-theme($theme);
  @include chat-theme($theme);

  // Pages
  @include register-theme($theme);
  @include reset-password-theme($theme);
  @include forgot-password-theme($theme);
  @include lock-theme($theme);
  @include mail-confirm-theme($theme);

  // Pages
  @include coming-soon-theme($theme);

  // UI
  //@include ui-cards-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes

$default-primary-palette: mat.define-palette($custom-primary);
$default-accent-palette: mat.define-palette($custom-secondary);
$default-warn-palette: mat.define-palette($custom-accent);

// Create the Material theme object
$theme: mat.define-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  // background: var(--color-main-content) !important;
  // color: var(--color-main-content-contrast) !important;
  background: var(--color-bg1) !important;
  color: var(--color-bg1-contrast) !important;

  .mat-table,
  .dashboard-graph,
  .mat-dialog-container,
  .mat-menu-item,
  .fuse-card,
  .mat-subheading-1,
  .mat-expansion-panel,
  .mat-header-cell,
  .mat-cell,
  .mat-autocomplete-panel,
  .mat-option,
  .mat-menu-content,
  .content-card,
  .mat-paginator-container {
    input {
      display: block;
      background: var(--color-card) !important;
      color: var(--color-card-contrast) !important;

      &.mat-input-element {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    background: var(--color-card) !important;
    color: var(--color-card-contrast) !important;
  }

  a:not(.nav-link),
  .mat-button.mat-accent {
    color: var(--color-accent) !important;
  }

  .mat-raised-button[color="accent"],
  .mat-fab.accent,
  .accent,
  div.accent,
  .mat-badge-content {
    background: var(--color-accent) !important;
    color: var(--color-accent-contrast) !important;
  }

  .mat-raised-button[color="secondary"] {
    background: var(--color-secondary) !important;
    color: var(--color-secondary-contrast) !important;
  }

  .info,
  .mat-raised-button.info,
  .mat-raised-button[color="info"] {
    background: var(--color-info) !important;
    color: var(--color-info-contrast) !important;
  }

  .error,
  .mat-raised-button.error,
  .mat-raised-button[color="error"] {
    background: var(--color-error) !important;
    color: var(--color-error-contrast) !important;
  }

  .message-box {
    a {
      color: var(--color-primary) !important;
    }
  }

  .warning,
  .warn,
  .mat-raised-button.warn,
  .mat-raised-button[color="warn"] {
    background: var(--color-warn) !important;
    color: var(--color-warn-contrast) !important;
  }

  .success,
  .mat-raised-button.success,
  .mat-raised-button[color="success"] {
    background: var(--color-success) !important;
    color: var(--color-success-contrast) !important;
  }

  .success-fg {
    color: var(--color-success) !important;
  }
  .warn-fg,
  .warning-fg {
    color: var(--color-warn) !important;
  }
  .error-fg {
    color: var(--color-error) !important;
  }
  .accent-fg {
    color: var(--color-accent) !important;
  }
  .primary-fg {
    color: var(--color-primary) !important;
  }
  .secondary-fg {
    color: var(--color-secondary) !important;
  }

  .blue-fg,
  .info-fg {
    color: var(--color-info) !important;
  }

  .green-fg,
  .success-fg {
    color: var(--color-success) !important;
  }

  .yellow-fg,
  .warning-fg {
    color: var(--color-warn) !important;
  }

  .red-fg,
  .error-fg {
    color: var(--color-error) !important;
  }

  .accent-fg {
    color: var(--color-accent) !important;
  }

  .top-bg,
  .mat-toolbar {
    background: var(--color-main-header) !important;
    color: var(--color-main-header-contrast) !important;
  }

  .main-toolbar {
    background: var(--color-toolbar) !important;
    color: var(--color-toolbar-contrast) !important;
  }

  .dialog-left-panel {
    background: var(--color-card) !important;
    color: var(--color-card-contrast) !important;
  }

  .dialog-right-panel {
    background: var(--color-primary) !important;
    color: var(--color-primary-contrast) !important;
  }

  .selected {
    background: var(--color-secondary) !important;
    color: var(--color-secondary-contrast) !important;
  }

  .mat-header-cell {
    opacity: 0.7 !important;
  }

  .mat-dialog-content {
    background: var(--color-card);
    color: var(--color-card-contrast);
  }

  form {
    border-radius: 8px !important;
  }

  .mat-error,
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label,
  .mat-form-field-invalid .mat-input-element,
  .mat-form-field.mat-form-field-invalid .mat-form-field-required-marker {
    color: var(--color-error) !important;
  }

  .text-wrap {
    word-wrap: normal !important;
    word-break: normal !important;
  }

  // Create an Angular Material theme from the $theme map
  @include mat.all-component-themes($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette
  );

@include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$dark-theme-primary-palette: mat.define-palette($custom-primary);
$dark-theme-accent-palette: mat.define-palette($custom-secondary);
$dark-theme-warn-palette: mat.define-palette($custom-accent);

// Create the Material theme object
$dark-theme: mat.define-dark-theme($dark-theme-primary-palette, $dark-theme-accent-palette, $dark-theme-warn-palette);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-dark {

  background: var(--color-main-content) !important;
  color: var(--color-main-content-contrast) !important;

  .mat-table,
  .mat-menu-item,
  .fuse-card,
  .mat-subheading-1,
  .mat-expansion-panel,
  .mat-header-cell,
  .mat-cell,
  .search-wrapper,
  .mat-autocomplete-panel,
  .mat-option,
  .mat-menu-content,
  .content-card,
  .mat-paginator-container {
    input {
      background: var(--color-card) !important;
      color: var(--color-card-contrast) !important;
    }

    background: var(--color-card) !important;
    color: var(--color-card-contrast) !important;
  }

  .info,
  .mat-raised-button.info,
  .mat-raised-button[color="info"] {
    background: var(--color-info) !important;
    color: var(--color-info-contrast) !important;
  }

  .error,
  .mat-raised-button.error,
  .mat-raised-button[color="error"] {
    background: var(--color-error) !important;
    color: var(--color-error-contrast) !important;
  }

  .warning,
  .warn,
  .mat-raised-button.warn,
  .mat-raised-button[color="warn"] {
    background: var(--color-warn) !important;
    color: var(--color-warn-contrast) !important;
  }

  .success,
  .mat-raised-button.success,
  .mat-raised-button[color="success"] {
    background: var(--color-success) !important;
    color: var(--color-success-contrast) !important;
  }

  .dashboard-graph,
  .top-bg,
  .mat-dialog-title {
    background: var(--color-main-header) !important;
    color: var(--color-main-header-contrast) !important;
  }

  .dialog-left-panel {
    background: white !important;
    color: var(--color-primary) !important;
  }

  .dialog-right-panel {
    background: var(--color-primary) !important;
    color: var(--color-primary-contrast) !important;
  }

  .selected {
    background: var(--color-secondary) !important;
    color: var(--color-secondary-contrast) !important;
  }

  .mat-header-cell {
    opacity: 0.7 !important;
  }

  // Generate the Angular Material theme
  @include mat.all-component-themes($dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($dark-theme);

  // Apply the theme to the user components
  @include components-theme($dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $dark-theme-primary-palette,
    accent: $dark-theme-accent-palette,
    warn: $dark-theme-warn-palette
  );

  @include fuse-color-classes($palettes);
}

.navbar-content {
  background: var(--color-main-menu) !important;
  color: var(--color-main-menu-contrast) !important;
}

.main-header {
  background: var(--color-main-header) !important;
  color: var(--color-main-header-contrast) !important;
}

.avatar-container {
  .avatar-content {
    max-height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.flex-flow-wrap {
  flex-flow: wrap !important;
}

html {
  @include mat.typography-level($typography, 'body-1');

  .mat-calendar-body-cell:hover {
    .mat-calendar-body-cell-content {
      color: white;
    }
  }
  .cdk-focused {
    .mat-calendar-body-active[aria-selected=false] .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical):hover {
      color: white !important;
    }

    .mat-calendar-body-active {
      background-color: --color-primary;

      &[aria-selected=false] {
        &:hover {
          .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
            color: white;
          }
        }
      }

      &.mat-calendar-body-cell[tabindex="0"] {
        .mat-calendar-body-cell-content.mat-focus-indicator {
          color: white !important;
        }
      }

      .mat-focus-indicator {
        color: white;
      }
    }

    .mat-calendar-body-cell:hover {
      .mat-calendar-body-cell-content {
        background-color: --color-primary;
        color: white;
      }
    }
  }
}

.title,
h1,
.headline {
  @include mat.typography-level($typography2, title);
}

.mat-snack-bar-container {
  button {
    background: var(--color-primary) !important;
    color: var(--color-primary-contrast) !important;
    margin: 4px 8px 4px 16px;
  }
}
