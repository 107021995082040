@use '@angular/material' as mat;
@mixin forgot-password-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    forgot-password {

        #forgot-password {

            #forgot-password-intro {
                color: white;
            }

            #forgot-password-form-wrapper {
                background: map-get($background, card);
            }
        }
    }
}