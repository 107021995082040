@mixin incomes-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  #incomes {

    .header {

      .search-wrapper {
        background: map-get($background, card);

        .search {

          .mat-icon {
            color: map-get($foreground, icon);
          }

          input {
            background: map-get($background, card);
            color: map-get($foreground, text);
          }
        }
      }
    }

    .content {

      .sidebar {

        .sidebar-content {

          .card {
            background: map-get($background, card);
          }
        }
      }
    }
    .filter-panel-status{
      .mat-expansion-panel-header{
        @include media-breakpoint('lt-sm') {
          padding: 0px 0px 0px 10px;
        }
        mat-panel-title{
          margin-right:0px;
        }
      }
      .mat-expansion-panel-body{
        padding: 0px !important;
      }
    }
    .filter-panel-type{
      .mat-expansion-panel-header{
        padding: 0px 0px 0px 10px;
        @include media-breakpoint('lt-sm') {
          padding: 0px 0px 0px 10px;
        }
      }
      .mat-expansion-panel-body{
        @include media-breakpoint('lt-md') {
          padding: 0px !important;
        }

      }
      .mat-expansion-panel-body{
        padding: 0px !important;
      }
    }
    mat-expansion-panel{
      .mat-expansion-panel-header{
        padding: 0px 0px 0px 10px;
      }
      margin:0  0  0 10px;
    }
  }
}
