@use '@angular/material' as mat;
@mixin mail-confirm-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $is-dark: map-get($theme, is-dark);

    mail-confirm {

        #mail-confirm {

            #mail-confirm-form-wrapper {

                #mail-confirm-form {
                    background: map-get($background, card);

                    .subtitle {
                        color: map-get($foreground, secondary-text);
                    }
                }
            }
        }
    }
}