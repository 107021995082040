@mixin map-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $is-dark: map-get($theme, is-dark);

    mat-progress-spinner{
        margin: 0 auto;
        top: 150px;
    }
}
