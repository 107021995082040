// Import Fuse core library
@import "@fuse/scss/core";
// Import app.theme.scss
@import "app/app.theme";
// Font awesome icons
@import 'node_modules/ngx-sharebuttons/themes/default.scss';

.grecaptcha-badge {
  visibility: hidden!important;
  z-index: 100;
}

.grecaptcha-error {
  visibility: hidden!important;
}

.show-element {
  visibility: visible!important;
}

.breakText {
  word-wrap: break-word;
}

.font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#chatbase-bubble-button {
  /* default styles for chatbase bubble-button, adapted with JS */
  position: fixed;
  bottom: 80px;
}

#add-item-button {
  position: fixed;
  bottom: 12px;
  right: 12px;
  padding: 0;
  z-index: 99;

  @include media-breakpoint('xs') {
      position: fixed;
      top: calc(100vh - 80px);
      bottom: 0;
  }

}

.bottom-toolbar-issue-fix {
  @include media-breakpoint('xs') {
      top: calc(92vh - 120px) !important;
  }
}

.mat-form-field-flex {
	align-items: center !important;
}
/* You can add global styles to this file, and also import other style files */


/*****************************************************
	Custom styling below.
*****************************************************/

.icon-success {
  color: var(--color-success) !important;
}

.icon-info {
  color: var(--color-info) !important;
}

.icon-warn {
  color: var(--color-warn) !important;
}

.icon-error {
  color: var(--color-error) !important;
}


.icon-accent {
  color: var(--color-accent) !important;
}
