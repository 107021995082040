@use '@angular/material' as mat;
@mixin reset-password-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    reset-password {

        #reset-password {

            #reset-password-intro {
                color: white;
            }

            #reset-password-form-wrapper {
                background: map-get($background, card);
                
            }
        }
    }
}