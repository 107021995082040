@mixin faq-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);
  faq {
    .header {
      .search {
        background: map-get($background, card);
        color: map-get($foreground, text);
        input {
          background: map-get($background, card);
          color: map-get($foreground, text);
        }
      }
    }
  }
}
