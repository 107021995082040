@mixin toolbar-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  toolbar {

    .toolbar-separator {
      background: map-get($foreground, divider);
    }

    #search-user, #search-operator {
      color: darkslategray; // var(--color-primary) !important;
      background: white; // var(--color-primary-contrast) !important;

      .search {
        color: darkslategray; // var(--color-primary) !important;
        background: white; /// var(--color-primary-contrast) !important;

        .mat-icon {
          color: var(--color-primary) !important;
          background: white !important; // var(--color-primary-contrast) !important;
        }

        input {
          color: darkslategray !important; //  var(--color-primary) !important;
          background: white !important; // var(--color-primary-contrast) !important;
        }
      }
    }
  }
}